<template>
    <v-tabs
        :class="['form-tabs', { 'hide-header': hideHeader }]"
        :value="value"
        show-arrows
        @change="$emit('input', $event)"
    >
        <slot></slot>
    </v-tabs>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        hideHeader: Boolean,
    },
}
</script>
