<template>
    <v-tab :disabled="disabled" :to="to" :exact="exact" :replace="replace">
        <v-badge
            color="error"
            :content="tabErrors[name]"
            :value="tabErrors[name] > 0"
        >
            <span>{{ title }}</span>
        </v-badge>
    </v-tab>
</template>

<script>
export default {
    props: {
        tabErrors: {
            type: Object,
            default: () => ({}),
        },
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        exact: {
            type: Boolean,
            default: false,
        },
        replace: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.v-tab {
    letter-spacing: normal;
}
</style>
