<template>
    <v-btn color="success" small @click="addOverride">
        <v-icon left>add</v-icon> {{ $t('actions.add') }}
    </v-btn>
</template>

<script>
export default {
    props: {
        baseLimit: {
            type: String,
            default: '0.00',
        },
    },

    computed: {
        overrideModel() {
            return {
                '@type': 'CCBudgetOverride',
                budgetLimit: this.baseLimit,
                user: null,
            }
        },
    },

    methods: {
        addOverride() {
            const id = new Date().getTime()
            const override = { ...this.overrideModel, '@id': id }
            this.$emit('added', override)
        },
    },
}
</script>
