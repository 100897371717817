export default {
    computed: {
        listFilters() {
            const filters = [
                {
                    key: 'deliveryTime',
                    inputType: 'checkbox',
                    label: 'labels.upcoming',
                    type: 'after',
                    value: this.$date().format(),
                    blocker: 'deliveryTime',
                    default: true,
                },
                {
                    key: 'deliveryTime',
                    inputType: 'checkbox',
                    label: 'labels.today_tomorrow_filter',
                    type: 'after',
                    value: this.$date().startOf('day').format(),
                    other: {
                        key: 'deliveryTime',
                        type: 'before',
                        value: this.$date().endOf('day').add(1, 'day').format(),
                    },
                    blocker: 'deliveryTime',
                    dateValidation: 'day',
                },
                {
                    key: 'deliveryTime',
                    type: 'after',
                    label: 'labels.delivery_time_from',
                    inputType: 'date',
                    listener: 'deliveryTime',
                    wider: true,
                },
                {
                    key: 'deliveryTime',
                    type: 'before',
                    label: 'labels.delivery_time_until',
                    inputType: 'date',
                    listener: 'deliveryTime',
                    wider: true,
                },
            ]
            return filters
        },
    },
}
