<template>
    <v-row :class="{ 'mb-8': extraSpace, 'pt-2': topSpace }">
        <v-col cols="12" :xl="xlSize" class="py-0">
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        extraSpace: Boolean,
        topSpace: {
            type: Boolean,
            default: true,
        },
        xlSize: {
            type: [Number, String],
            default: '9',
        },
    },
}
</script>
