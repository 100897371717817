<template>
    <v-combobox
        :label="labelText"
        :error-messages="errorMessages"
        :autofocus="autofocus"
        multiple
        small-chips
        :value="value"
        deletable-chips
        @input="$emit('input', $event)"
    ></v-combobox>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: [Number, String, Object, Array],
            default: null,
        },
        autofocus: {
            type: Boolean,
        },
    },
}
</script>
