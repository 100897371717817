<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="{ company: { exact: company }, ...fixedFilters }"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template v-slot:filters="{ busy }">
            <table-filters
                v-model="filters"
                :fields="headers"
                :busy="busy"
                :list-filters="listFilters"
                hide-search-box
                class="mt-4"
                @input="updateFilters"
            ></table-filters>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                row-action
                @onRowSelected="onRowSelected"
            >
                <!-- name column -->
                <template v-slot:[`item.name`]="{ item }">
                    <router-link
                        :to="getEditLink(item)"
                        class="canteen-name text-truncate"
                        :class="isEditable(item) ? '' : 'grey--text'"
                    >
                        {{ item.name }}
                    </router-link>
                </template>

                <!-- cutoff time -->
                <template v-slot:[`item.cutoffTime`]="{ item }">
                    {{ item.cutoffTime | transformDate }}
                </template>

                <!-- delivery at -->
                <template v-slot:[`item.deliveryTime`]="{ item }">
                    {{ item.deliveryTime | transformDate }} -
                    {{ item.deliveryTime | transformDate(30, true) }}
                </template>

                <!-- budget column -->
                <template v-slot:[`item.budgetLimit`]="{ item }">
                    {{ item.budgetLimit | currency }}
                </template>

                <!-- isRecurring column -->
                <template v-slot:[`item.isRecurring`]="{ item }">
                    <v-chip outlined :color="item.isRecurring ? 'green' : ''">
                        {{ item.isRecurring ? 'Yes' : 'No' }}
                    </v-chip>
                </template>

                <!-- preCreateDeliveryEnabled column -->
                <template v-slot:[`item.preCreateDeliveryEnabled`]="{ item }">
                    <edit-button
                        v-if="item.preCreateDeliveryEnabled"
                        icon="more_time"
                        :to="{
                            name: routeType.DELIVERY_LIST,
                            query: {
                                'preCreatedFor[type]': 'cloud_canteen',
                                'preCreatedFor[id]': item.id,
                            },
                        }"
                    ></edit-button>
                </template>

                <!-- canteenEmailSentToRestaurant column -->
                <template
                    v-slot:[`item.relatedRestaurants`]="{
                        item,
                    }"
                >
                    <span
                        v-if="
                            item.relatedRestaurants[0]
                                .canteenEmailSentToRestaurant
                        "
                        ><v-icon color="green">done</v-icon></span
                    >
                    <span v-else>{{
                        item.restaurantCanteenEmailSendTime | transformDate
                    }}</span>
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <edit-button
                        :icon="isEditable(item) ? 'edit' : 'preview'"
                        :tooltip="isEditable(item) ? '' : $t('actions.view')"
                        :to="getEditLink(item)"
                    ></edit-button>
                    <table-button
                        v-if="
                            item.preCreateDeliveryEnabled === false &&
                            $date(item.cutoffTime) > $date()
                        "
                        :tooltip="$t('actions.send_pre_delivery')"
                        color="blue-grey"
                        icon="two_wheeler"
                        approve-color="error"
                        @execute=";(dialog = true), (selectedItem = item)"
                    ></table-button>
                    <delete-action
                        v-if="isEditable(item)"
                        :entity-name="item.name"
                        :entity-id="item.id"
                        :api="api"
                        @deleted="onEntityDeleted"
                        @failed="onEntityDeleteFailed"
                    ></delete-action>
                </template>
            </d-table>
            <confirm-modal
                v-model="dialog"
                :title="$t('texts.request_courier')"
                :text="
                    $t('texts.do_you_want_request_courier', {
                        item: `${selectedItemIdentifier}`,
                    })
                "
                :order-id="selectedItemIdentifier"
                approve-color="green"
                :approve-text="$t('actions.confirm')"
                @onApprove="sendPreDelivery"
                @onCancel=";(selectedItem = null), (dialog = false)"
            ></confirm-modal>
        </template>
    </data-iterator>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import RestApiCollection from '@/api/RestApiCollection'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from './mixins/Headers'
import ListFilters from './mixins/ListFilters'
import Notification from '@/services/Notification/Notification'
import ConfirmModal from '@/components/confirm/ConfirmPreDeliveryModal'
import TableButton from '@/components/table/buttons/TableButton'

export default {
    components: {
        TableButton,
        ConfirmModal,
    },
    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],
    props: {
        companyId: {
            type: [String, Number],
            required: true,
        },
        fixedFilters: {
            type: Object,
            default: () => {
                return {
                    order: {
                        deliveryTime: 'desc',
                    },
                }
            },
        },
    },
    data() {
        return {
            api: RestApiType.COMPANY_CLOUDCANTEENS,
            dialog: false,
            selectedItem: null,
            filters: {},
        }
    },
    computed: {
        company() {
            return RestApiCollection.get(RestApiType.COMPANIES).getItemIri(
                this.companyId
            )
        },
        selectedItemIdentifier() {
            return this.selectedItem?.identifier || null
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.COMPANY_CLOUDCANTEEN_EDIT,
                params: { companyId: this.companyId, cloudcanteenId: item.id },
            }
        },
        isEditable(item) {
            return this.$date().isBefore(item.cutoffTime, 'second')
        },
        sendPreDelivery(deliveryMethod) {
            RestApiCollection.get(RestApiType.DELIVERIES)
                .preCreateDeliveryCC(this.selectedItem.id, deliveryMethod)
                .then((data) => {
                    if (data.success === false) {
                        Notification.error(
                            this.$t('notifications.pre_delivery_error.title', {
                                id: this.selectedItem.identifier,
                            }),
                            data.message
                        )
                    } else {
                        Notification.success(
                            this.$t(
                                'notifications.pre_delivery_success.title',
                                {
                                    id: this.selectedItem.identifier,
                                }
                            ),
                            data.message
                        )
                    }
                })
                .catch(() => {
                    this.$emit('failed', this.selectedItem.id)
                    Notification.error(
                        this.$t('notifications.pre_delivery_failed.title', {
                            id: this.selectedItem.identifier,
                        })
                    )
                })
                .finally(() => {
                    this.$refs.iterator.updateItems(true, true)
                })
        },
    },
}
</script>
