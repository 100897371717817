import { mapGetters } from 'vuex'
import { LABEL_PH } from '@/defaults'
import selectapiType from '@/store/type/selectapiType'
import PageTitle from '@/components/layout/components/pageTitle'
import authType from '@/store/type/authType'
import routeInfoType from '@/store/type/routeInfoType'

export default (clientType, propertyName) => ({
    components: { PageTitle },
    data: () => ({
        selectApiTitle: LABEL_PH,
    }),
    computed: {
        ...mapGetters({
            isSuperAdmin: authType.getters.IS_SUPER_ADMIN,
            routeInfoUsers: routeInfoType.getters.GET_USERS,
            routeInfoCompanies: routeInfoType.getters.GET_COMPANIES,
        }),
        title() {
            if (!this.isSuperAdmin) {
                switch (clientType) {
                    case selectapiType.clientTypes.USER:
                        return `${this.routeInfoUser?.firstName} ${this.routeInfoUser?.lastName}`
                    case selectapiType.clientTypes.COMPANY:
                        return this.routeInfoCompany?.name
                }
            }

            return this.selectApiTitle
        },
        routeId() {
            return parseInt(this[propertyName])
        },
        routeInfoUser() {
            return this.routeInfoUsers?.[this.routeId]
        },
        routeInfoCompany() {
            return this.routeInfoCompanies?.[this.routeId]
        },
    },
    methods: {
        loadTitleData() {
            if (!this.isSuperAdmin) {
                switch (clientType) {
                    case selectapiType.clientTypes.USER:
                        return this.$store.dispatch(
                            routeInfoType.actions.GET_USER,
                            this.routeId
                        )
                    case selectapiType.clientTypes.COMPANY:
                        return this.$store.dispatch(
                            routeInfoType.actions.GET_COMPANY,
                            this.routeId
                        )
                }
            }

            return this.$store
                .dispatch(
                    selectapiType.getActionName(
                        clientType,
                        selectapiType.actions.LOAD
                    )
                )
                .then((data) => {
                    const comp = data.find((val) => val.id === this.routeId)
                    if (comp) {
                        this.selectApiTitle = comp.name
                    }
                })
        },
    },
    mounted() {
        this.loadTitleData()
    },
})
